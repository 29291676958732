<template>
    <div class="share">
        <transition name="poput">
            <div class="poput-bg">
                <div class="poput-share">
                    <div class="pic" ref="imageWrapper" id="sPage">
                        <div class="nickname">
                            {{name}} 邀请你一起学习
                        </div>
                        <div class="class-name">{{data.class_name}}</div>
                        <div class="photo">
                            <div><img :src="domainUrl + data.photo+'?'+(new Date().getTime()+Math.round(Math.random()*10))" crossOrigin="anonymous"></div>
                        </div>
                        <!-- <div class="desc">
                            <template v-if="data.share_desc">{{data.share_desc}}</template>
                            <template v-else>{{data.feature}}</template>
                        </div> -->
                        <div class="code">
                            <div class="code-img"><div id="qrcode" ref="qrcode"></div></div>
                            <div class="text">长按识别二维码<br>查看具体课程详情~！</div>
                        </div>
                    </div>
                    <div class="shareimg"><img :src="imgUrl" alt=""></div>
                    <div class="foot">
                        <div class="text">长按图片分享给好友</div>
                        <div @click="$emit('close')" class="cancel">取消</div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import Vue from 'vue'
import html2canvas from 'html2canvas';
import QRCode from 'qrcodejs2'
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            name: Vue.getUser().name,
            imgUrl: '',
            photo: ''
        }
    },
    created(){
        window.scroll(0,0)
    },
    mounted() {
        this.qrcode();
        const that = this;
        that.$nextTick(function () {
            that.toImage();         
        });

    },
    methods: {
        qrcode() {
            let qrcode = new QRCode('qrcode', {
                width: 65, // 设置宽度，单位像素
                height: 65, // 设置高度，单位像素
                background:'#fff',
                text: this.data.share_url ? this.data.share_url : 'http://school.hailuoguniang.com/#/course/login?parent_id=' + this.data.id // 设置二维码内容或跳转地址
            })
        },
        toImage() {
            var targetDom = document.getElementById("sPage");
            const opts = {
                useCORS: true,
                height: targetDom.offsetHeight, //注意 下面解决当页面滚动之后生成图片出现白边问题
                width: targetDom.offsetWidth,
            }
            html2canvas(this.$refs.imageWrapper, opts).then(canvas => {
                let dataURL = canvas.toDataURL("image/png");
                this.imgUrl = dataURL;
                if (this.imgUrl !== "") {
                    this.dialogTableVisible = true;
                }
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.poput-share {
    .pic {
        width: 686px;
        margin: 50px auto;
        padding: 32px;
        background: url("../../../assets/images/course/share_bg.png") no-repeat;
        background-size: 100% auto;
        position: absolute;
        top: -100%;
        left: 32px;
        z-index: 2;
        .nickname {
            font-size: 32px;
            margin-bottom: 32px;
            text-align: left;
        }
        .class-name {
            font-size: 34px;
            line-height: 46px;
            margin-bottom: 65px;
        }
        .photo {
            position: relative;
            padding: 18px 10px 10px 10px;
            margin-bottom: 62px;
            background: #fff;
            > div {
                height: 372px;
                overflow: hidden;
            }
            &::before {
                position: absolute;
                content: "";
                top: -120px;
                right: 0;
                width: 124px;
                height: 141px;
                background: url("../../../assets/images/course/girl.png")
                    no-repeat;
                background-size: 100%;
            }
            img {
                width: 100%;
                height: 372px;
                object-fit: cover;
            }
        }
        .desc {
            font-size: 32px;
            line-height: 46px;
            text-align: left;
            margin-bottom: 22px;
        }
        .code {
            text-align: left;
            .code-img {
                display: inline-block;
                margin-right: 16px;
                background: #fff;
                text-align: center;
                line-height: 0;
                // padding: 8px;
                >div{
                    margin: 8px;
                }
            }
            .text {
                width: calc(100% - 186px);
                vertical-align: top;
                display: inline-block;
                height: 100%;
                background: #fde79f url("../../../assets/images/course/boy.png")
                    360px bottom no-repeat;
                background-size: 88px auto;
                padding: 25px;
                font-size: 32px;
                color: #977913;
            }
        }
    }
    .shareimg {
        width: 686px;
        margin: 150px auto;
        position: relative;
        z-index: 2;
    }
    .foot {
        position: fixed;
        bottom: 0px;
        width: 100%;
        left: 0;
        margin: 0 auto;
        background: #fff;
        text-align: center;
        .text {
            background: #f6f6f6;
            line-height: 96px;
            font-size: 30px;
        }
        .cancel {
            line-height: 100px;
            font-size: #666;
            font-size: 30px;
        }
    }
}
</style>